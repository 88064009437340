body {
	background: white;
	font-family: -apple-system, BlinkMacSystemFont, 'Avenir', Arial, sans-serif;
	color:#555355;
	-webkit-font-smoothing: antialiased;
  	-moz-osx-font-smoothing: grayscale;
    margin: 0;
  	padding: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
